import * as React from "react"
import { Container, Box, Grid, Typography, makeStyles } from "@material-ui/core"
import Seo from "../../../components/seo"
import Menu from "../../../components/festival/menu"
import Band from "../../../components/festival/band"
import { StaticImage } from "gatsby-plugin-image"
import {Link} from 'gatsby'

const winokio = <>Tijdens dit swingende concert zingt Kapitein Winokio - samen met zijn muziekmatrozen - hartverwarmende melodietjes uit RADIO WINOKIO. Op reis in eigen land met heerlijke Caraïbische ritmes en zomerzonnige hits. Hoe heerlijk is dat? Kapitein Winokio is een meester in zijn vak en brengt jou en je Kampioenen moeiteloos in vakantiestemming. Wàt gaan wij doen? Zingen, swingen en chillen in de hangmat naast de barbecue - I love you!</>

const winokioImage =  <StaticImage 
src="../../../images/festival/vrijdagmiddag/kapitein-winokio.png" 
alt="Kapitein Winokio"
placeholder="blurred"
/>  

const useStyles = makeStyles(theme => ({
  pijlRechts: {
      float: 'right',
  },
  pijlLinks: {
    float: 'left',
},
  title:{
    textAlign: "center"
  }
 }));


const VrijdagMiddagPage = () => {
  const classes = useStyles();
  return (

    <Container maxWidth="lg">
      <Seo title="Tomatostories - Lineup vrijdagmiddag" />
      <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Menu/>
            </Grid>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h4" component="h1" color="primary">
                <Link to="/festival/lineup/donderdag">
                  <StaticImage 
                      src="../../../images/pijl-links.png" 
                      alt="donderdag 19/08" 
                      placeholder="tracedSVG"
                      height={60}
                      className={classes.pijlLinks}
                      />
                 </Link>
                 vrijdag 18/08 - namiddag
                  <Link to="/festival/lineup/vrijdagavond">
                  <StaticImage 
                      src="../../../images/pijl-rechts.png" 
                      alt="Vrijdagavond 20/08" 
                      placeholder="tracedSVG"
                      height={60}
                      className={classes.pijlRechts}
                      />
                 </Link>
              </Typography>
              
            </Grid>
            <Band text={winokio} time="14u" title="Kapitein Winokio" image={winokioImage} orientation="left"/>
          </Grid>
        </Box>    
      </Container>

)}

export default VrijdagMiddagPage
